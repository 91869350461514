import { Button, Stack, TextField } from "@mui/material";
import * as React from "react";

export default function TextInput({
  handleSubmit,
}: {
  handleSubmit: (data: string) => void;
}) {
  const [text, setText] = React.useState("");

  return (
    <Stack alignItems="center" spacing={3}>
      <TextField
        value={text}
        onChange={(e) => setText(e.target.value)}
        id="text-input"
        label="Paste your text here"
        multiline
        fullWidth
        rows={6}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={text === ""}
        sx={{ width: 90 }}
        onClick={() => handleSubmit(text)}
      >
        Submit
      </Button>
    </Stack>
  );
}
