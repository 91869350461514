import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Input = styled("input")({
  display: "none",
});

export default function UploadFields({
  setData,
  setLoading,
  setError,
}: {
  setData: (data: any) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string) => void;
}) {
  const [file, setFile] = React.useState<File | null>(null);
  return (
    <>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div>
          <label htmlFor="file-input">
            <Input
              accept="text/*"
              id="file-input"
              type="file"
              onChange={() => {
                // @ts-ignore
                setFile(document.getElementById("file-input")?.files[0]);
              }}
            />
            <Button variant="contained" component="span" color="secondary">
              Select Text File
            </Button>
          </label>
          <Button
            variant="contained"
            disabled={!file}
            onClick={async () => {
              if (!file) {
                setError("No file selected");
                return;
              }
              let data = new FormData();
              data.append("file", file);

              setError("");
              setLoading(true);

              try {
                const res = await fetch(
                  `${process.env.REACT_APP_SERVER_URL}/upload`,
                  {
                    method: "POST",
                    body: data,
                  }
                );
                if (res.ok) {
                  const resData = await res.json();
                  setData(resData.data);
                }
              } catch (error: any) {
                setError(error.message);
              }
              setLoading(false);
            }}
          >
            Upload
          </Button>
        </div>
      </div>
      <Typography align="center">
        {file && "Selected file: " + file.name}
      </Typography>
    </>
  );
}
