import { Alert, Button, Stack } from "@mui/material";
import * as React from "react";
import { ISentenceData } from "../../../types";
import Sentence from "./Sentence";

export default function ResultPage({ data }: { data: ISentenceData[] }) {
  // @ts-ignore
  function download(content, fileName, contentType) {
    var a = document.createElement("a");
    var file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }
  return (
    <>
      <Alert
        severity="info"
        style={{
          margin: "auto",
          width: "50%",
          marginBottom: 20,
          display: "flex",
          alignItems: "center",
        }}
        action={
          <Button
            onClick={() =>
              download(JSON.stringify(data), "result.json", "text/plain")
            }
          >
            Download Result
          </Button>
        }
      >
        The total sentiment score is{" "}
        {data.reduce((acc, cur) => acc + cur.sentence_score, 0)}
      </Alert>
      <Stack spacing={2}>
        {data.map((sentence, index) => {
          return <Sentence key={index} data={sentence} />;
        })}
      </Stack>
    </>
  );
}
