import { Chip, Paper } from "@mui/material";
import * as React from "react";
import { ISentenceData } from "../../../types";
import SentenceDetail from "./SentenceDetail";

export default function Sentence({ data }: { data: ISentenceData }) {
  const { sentence_score } = data;

  return (
    <Paper>
      <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
        <Chip
          label={sentence_score}
          sx={{ marginRight: 2 }}
          color={
            sentence_score > 0
              ? "success"
              : sentence_score === 0
              ? "default"
              : "error"
          }
        />
        <SentenceDetail data={data} />
      </div>
    </Paper>
  );
}
