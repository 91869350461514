import {
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { ISentenceData } from "../../types";
import FileUploader from "./FileUploader";
import ResultPage from "./result/Index";
import TextInput from "./TextInput";

export default function IndexPage() {
  const resRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = React.useState<ISentenceData[] | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async (text: string) => {
    setError("");
    setLoading(true);
    resRef.current?.scrollIntoView({ behavior: "smooth" });

    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: text,
        }),
      });
      if (res.ok) {
        const resData = await res.json();
        // console.log(resData);
        setData(resData.data);
      }
    } catch (error: any) {
      setError(error.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={5}>
          <Typography variant="h2">WKWSCI Sentiment Analysis</Typography>
          <TextInput handleSubmit={handleSubmit} />
          <Divider>OR</Divider>
          <FileUploader
            setData={setData}
            setLoading={setLoading}
            setError={setError}
          />
        </Stack>

        <div ref={resRef}>
          <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        </div>

        <div style={{ minHeight: 1000 }}>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {error && <Typography variant="body1">{error}</Typography>}
          {data && <ResultPage data={data} />}
        </div>
      </Container>
    </>
  );
}
