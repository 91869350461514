import { Tooltip } from "@mui/material";
import { green, lightGreen, orange, red } from "@mui/material/colors";
import * as React from "react";
import { ISentenceData } from "../../../types";

const scoreToColor = {
  "-3": red[700],
  "-2": red[500],
  "-1": red[200],
  "0": "black",
  "1": lightGreen[500],
  "2": green[500],
  "3": green[700],
};

export default function SentenceDetail({ data }: { data: ISentenceData }) {
  const { words, modifiers } = data;

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {words.map(([word, pos, score], index) => {
        let isModifier = false;
        let modifier = "";
        // @ts-ignore
        if (modifiers[word] !== undefined) {
          isModifier = true;
          // @ts-ignore
          modifier = modifiers[word];
        }

        return (
          <Tooltip
            title={isModifier ? `${modifier}` : `pos: ${pos}, score: ${score}`}
            key={index}
          >
            <span
              style={{
                color: isModifier
                  ? orange[500]
                  : // @ts-ignore
                    scoreToColor[parseInt(score, 10).toString()],
                padding: 3,
                // fontSize: "1.2rem",
              }}
            >
              {word}
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
}
